import React, { useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import Fab from '@material-ui/core/Fab';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DataContext from '../contexts/DataContext';
import { read } from '../services/api';
import logger from '../services/logger';
import { RolesIcon } from '../shared/icons';
import ApplicationSelector from '../shared/ApplicationSelector';
import FullPageLoader from '../shared/FullPageLoader';
import defaultStyles from '../App.css.js';
import EditPolicy from './EditPolicy';

function User({ classes }) {
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);
  const [editingPolicy, setEditingPolicy] = useState();
  const [creatingPolicy, setCreatingPolicy] = useState(false);
  const [policies, setPolicies] = useState();
  const { roles, tenants, refreshRoles, refreshTenants, sessionApplication } = useContext(DataContext);
  const { id } = useParams();
  const history = useHistory();

  const fetchUser = useCallback(async () => {
    try {
      setUser(await read('/users', id));
    } catch (err) {
      logger.error(`${id}: user not found`);
      history.push('/users');
    }
  }, [history, id]);

  const refreshPolicyList = useCallback(async () => {
    if (user && roles && tenants) {
      setLoading(true);
      read(`/users/${user.id}/policies`).then((results) => {
        const prefetchedRoleIds = roles.map(({ id }) => id);
        const prefetchedTenantIds = tenants.map(({ id }) => id);

        const policies = {};
        let rolesAreStale = false;
        let tenantsAreStale = false;
        results
          .filter((p) => p.role && p.tenant)  // (sanity check)
          .sort((a, b) => {
            if (a.role.name === b.role.name) {
              return a.tenant.name.toLowerCase() < b.tenant.name.toLowerCase() ? -1 : 1;
            }
            return a.role.name.toLowerCase() < b.role.name.toLowerCase() ? -1 : 1;
          })
          .forEach(({ id, role, tenant }) => {
            if (!policies[role.id]) {
              policies[role.id] = {
                role,
                tenants: []
              };
              rolesAreStale = rolesAreStale || !prefetchedRoleIds.includes(role.id);
            }
            policies[role.id].tenants.push({
              ...tenant,
              policyId: id
            });
            tenantsAreStale = tenantsAreStale || !prefetchedTenantIds.includes(tenant.id);
          });
        setPolicies(Object.values(policies));
        setLoading(false);

        // sanity check (if there is a role or tenant in user data that is not in prefetch data, prefetch roles/tenants again)
        if (rolesAreStale) {
          refreshRoles();
        }
        if (tenantsAreStale) {
          refreshTenants();
        }
      });
    }
  }, [refreshRoles, refreshTenants, roles, tenants, user]);

  useEffect(() => {
    if (!user) fetchUser();
  }, [fetchUser, user]);
  useEffect(() => {
    if (!policies) refreshPolicyList();
  }, [refreshPolicyList, policies]);

  const handleCreatePolicy = () => {
    setCreatingPolicy(true);
  };
  const handleEditPolicy = (policy) => () => {
    setEditingPolicy(policy);
  };
  const handlePolicyDialogClose = () => {
    setCreatingPolicy(false);
    setEditingPolicy();
  };

  const appPolicies = (policies || []).filter((p) => p.role.application === sessionApplication.id);
  return (
    <>
      {(creatingPolicy || editingPolicy) && (
        <EditPolicy
          handleClose={handlePolicyDialogClose}
          onChange={refreshPolicyList}
          policies={appPolicies || []}
          selectedPolicy={editingPolicy}
          user={user} />
      )}

      {user && (
        <div className={classes.titleWithSelector}>
          <div style={{ flex: 1 }}>
            <Typography variant="h5" component="span">{user.name || user.email}</Typography>
            <Typography variant="caption" component="span" style={{ marginLeft: 8 }}>{user.email}</Typography>
          </div>
          <ApplicationSelector />
        </div>
      )}

      <Paper className={classes.mainPaper}>
        {loading && <FullPageLoader />}
        {!loading && (
          <>
            <Fab color="primary" className={classes.fab} onClick={handleCreatePolicy}>
              <AddIcon />
            </Fab>
            {appPolicies.length === 0
              ? (
                <Typography className={classes.na}>This user has no policies granted for this application.</Typography>
              )
              : (
                <List>
                  {(appPolicies || []).map((policy, index) => (
                    <React.Fragment key={index}>
                      <ListItem
                        className={classes.clickableListItem}
                        onClick={handleEditPolicy(policy)}>
                        <ListItemIcon><RolesIcon /></ListItemIcon>
                        <ListItemText
                          primary={policy.role.name}
                          secondary={policy.tenants.map((t) => t.name).join(', ')} />
                        <ListItemSecondaryAction>
                          <IconButton onClick={handleEditPolicy(policy)}>
                            <EditIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                      <Divider />
                    </React.Fragment>
                  ))}
                </List>
              )
            }
          </>
        )}
      </Paper>
    </>
  );
}
User.propTypes = {
  classes: PropTypes.object
};

export default withStyles(defaultStyles)(User);
