import React, { useContext } from 'react';
//import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import DataContext from '../contexts/DataContext';

export function ApplicationSelector() {
  const { applications, sessionApplication, setSessionApplication } = useContext(DataContext);

  const handleChange = (event) => {
    sessionStorage.setItem('selectedApplicationId', event.target.value.id);
    setSessionApplication(event.target.value);
  };

  return (
    <FormControl variant="outlined" size="small">
      <InputLabel id="demo-simple-select-outlined-label">
        Application
      </InputLabel>
      <Select
        labelId="demo-simple-select-outlined-label"
        labelWidth={80}
        onChange={handleChange}
        value={sessionApplication}
        style={{ minWidth: 200 }}>
        {applications.map((app, x) => (
          <MenuItem key={x} value={app}>{app.name}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
ApplicationSelector.propTypes = {};

export default ApplicationSelector;
