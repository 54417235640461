import React, { useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import SearchIcon from '@material-ui/icons/Search';
import DataContext from '../contexts/DataContext';
import { ApplicationsIcon } from '../shared/icons';
import ErrorSnackbar from '../shared/ErrorSnackbar';
import FullPageLoader from '../shared/FullPageLoader';
import { read } from '../services/api';
import defaultStyles from '../App.css.js';

export function Applications({ classes }) {
  const [error, setError] = useState();
  const [searching, setSearching] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const { applications, refreshApplications } = useContext(DataContext);

  const handleSearch = useCallback((term) => {
    setSearching(true);
    read('/applications', null, { searchTerm: term }).then(
      (results) => {
        setSearchResults(results || []);
        setSearching(false);
      },
      () => {
        setError('Search could not be performed as typed. Please check your search string and try again.');
        setSearchResults([]);
        setSearching(false);
      }
    );
  }, []);

  useEffect(() => {
    handleSearch('');
  }, [handleSearch]);

  const handleSearchTermChange = (event) => {
    const updatedSearch = event.target.value;
    setSearchTerm(updatedSearch);
  };

  const handleSearchEnter = (event) => {
    if (event.key === 'Enter') {
      handleSearch(searchTerm);
    }
  };

  const handleCreateApplication = (application) => () => {
    // TODO: this should maybe be a "create" (api change required)
    read('/applications', application.clientId).then(() => {
      refreshApplications();
    });
  };

  const handleDismissError = () => {
    setError();
  };

  return (
    <>
      <ErrorSnackbar error={error} onDismiss={handleDismissError} />

      <div className={classes.titleWithSearch}>
        <Typography variant="h5">Application Management</Typography>
        <div className={classes.titleSearchContainer}>
          <TextField
            autoFocus
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start"><SearchIcon /></InputAdornment>
              )
            }}
            label="Application Search"
            onChange={handleSearchTermChange}
            onKeyPress={handleSearchEnter}
            size="small"
            value={searchTerm}
            variant="outlined" />
        </div>
      </div>
      
      <Paper className={classes.mainPaper}>
        {searching && <FullPageLoader />}
        {!searching && (
          <List>
            {searchResults.map((result, x) => {
              const existingApp = applications.find((app) => app.clientId === result.clientId);
              return (
                <React.Fragment key={x}>
                  <ListItem>
                    <ListItemIcon><ApplicationsIcon /></ListItemIcon>
                    <ListItemText
                      primary={(
                        <>
                          {result.name}
                          <span className={classes.listItemPrimaryExtra}>
                            {existingApp ? `${existingApp.id}` : '(click "+" to generate application id)'}
                          </span>
                        </>
                      )}
                      secondary={`Auth0 Client ID: ${result.clientId}`} />
                    <ListItemSecondaryAction>
                      {existingApp
                        ? (
                          <IconButton disabled>
                            <CheckIcon />
                          </IconButton>
                        )
                        : (
                          <Tooltip title="Import App" placement="left">
                            <IconButton
                              classes={{ root: classes.visiblePrimaryIconButton }}
                              color="primary"
                              onClick={handleCreateApplication(result)}>
                              <AddIcon />
                            </IconButton>
                          </Tooltip>
                        )
                      }
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                </React.Fragment>
              );
            })}
          </List>
        )}
      </Paper>
    </>
  );
}
Applications.propTypes = {
  classes: PropTypes.object
};

export default withStyles(defaultStyles)(Applications);
