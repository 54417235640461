import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { create } from '../services/api';
import ErrorSnackbar from '../shared/ErrorSnackbar';

export function CreateRole({ application, handleClose, onChange, roles }) {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState();

  const handleChange = (field) => (event) => {
    const updatedValue = event.target.value;
    if (field === 'name') {
      setName(updatedValue);

      // light validation
      if (!updatedValue) {
        setError('A role name is required.');
      } else if (roles.find((r) => r.name.toLowerCase() === updatedValue.toLowerCase().trim())) {
        setError('A role already exists with that name.');
      } else {
        setError();
      }
    } else if (field === 'description') {
      setDescription(updatedValue);
    }
  };
  const handleSave = () => {
    if (name && !error) {
      setSaving(true);
      create('/roles', { application, description, name }).then(
        (newRole) => {
          if (onChange) {
            onChange(newRole);
          }
          handleClose();
        },
        (err) => {
          if (err.message) {
            setError(err.message);
          } else if (err.status === 403) {
            setError('You do not have permission to do that.');
          } else {
            setError('An error has occurred. Please try again.');
          }
          setSaving(false);
        }
      );
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSave();
    }
  };

  const handleDismissError = () => {
    setError();
  };

  return (
    <>
      <Dialog open={true} onClose={handleClose}>
        <DialogTitle>Create New Role</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            fullWidth
            label="Role Label"
            margin="dense"
            onChange={handleChange('name')}
            onKeyPress={handleKeyPress}
            type="text"
            value={name} />

          <TextField
            fullWidth
            label="Role Description"
            margin="dense"
            onChange={handleChange('description')}
            onKeyPress={handleKeyPress}
            type="text"
            value={description} />
        </DialogContent>
        <DialogActions>
          <Button disabled={saving} onClick={handleClose} color="default">
            Cancel
          </Button>
          <Button
            disabled={saving} onClick={handleSave} color="primary"
            variant="contained">
            Create
          </Button>
        </DialogActions>
      </Dialog>
      <ErrorSnackbar error={error} onDismiss={handleDismissError} />
    </>
  );
}
CreateRole.propTypes = {
  application: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  roles: PropTypes.array.isRequired
};

export default CreateRole;