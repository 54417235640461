import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import DataContext from '../contexts/DataContext';
import ErrorSnackbar from '../shared/ErrorSnackbar';
import { create, destroy } from '../services/api';
import defaultStyles from '../App.css.js';

export function EditPolicy({ classes, handleClose, onChange, policies, selectedPolicy, machine }) {
  const [role, setRole] = useState('');
  const [roleLocked, setRoleLocked] = useState();
  const [selectedTenantIds, setSelectedTenantIds] = useState([]);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState();
  const { roles, tenants, sessionApplication } = useContext(DataContext);

  useEffect(() => {
    if (selectedPolicy) {
      setRole(roles.find((r) => r.id === selectedPolicy.role.id));
      setRoleLocked(true);
      setSelectedTenantIds(selectedPolicy.tenants.map((t) => t.id));
    } else {
      setRole('');
      setRoleLocked(false);
      setSelectedTenantIds([]);
    }
  }, [roles, selectedPolicy]);

  const handleSelectRole = (event) => {
    setRole(event.target.value);

    const existingPolicy = policies.find((p) => p.role.id === event.target.value.id);
    setSelectedTenantIds(existingPolicy ? existingPolicy.tenants.map((t) => t.id) : []);
    setError();
  };
  const handleToggleTenant = (tenant) => () => {
    if (role) {
      if (selectedTenantIds.includes(tenant.id)) {
        setSelectedTenantIds(selectedTenantIds.filter((t) => t !== tenant.id));
      } else {
        setSelectedTenantIds([...selectedTenantIds, tenant.id]);
      }
    }
    setError();
  };

  const handleSave = () => {
    if (!error) {
      if (role) {
        const originalTenants = (policies.find((p) => p.role.id === role.id) || {}).tenants || [];
        const createPolicies = selectedTenantIds.filter((id) => !originalTenants.find((t2) => t2.id === id));
        const deletePolicies = originalTenants.filter((t) => !selectedTenantIds.includes(t.id)).map((p) => p.policyId);
        if (createPolicies.length > 0 || deletePolicies.length > 0) {
          setSaving(true);
          Promise.all([
            Promise.all(
              createPolicies.map((tenant) => create(`/machines/${machine.id}/policies`, {
                role: role.id,
                tenant: (tenant.id || tenant),
              }))
            ),
            Promise.all(
              deletePolicies.map((policyId) => destroy(`/machines/${machine.id}/policies`, policyId))
            )
          ]).then(
            () => {
              if (onChange) {
                onChange();
              }
              handleClose();
            },
            (err) => {
              if (err.message) {
                setError(err.message);
              } else if (err.status === 403) {
                setError('You do not have permission to do that.');
              } else {
                setError('An error has occurred. Please try again.');
              }
              setSaving(false);
            }
          );
        } else {
          setError('At least one change is required.');
        }
      } else {
        setError('A role selection is required.');
      }
    }
  };

  const handleDismissError = () => {
    setError();
  };

  const appRoles = roles.filter((r) => r.application === sessionApplication.id);
  return (
    <>
      <Dialog open={true} onClose={handleClose}>
        <DialogTitle>{selectedPolicy ? 'Edit' : 'Add'} Machine Policy</DialogTitle>
        <DialogContent>
          <FormControl style={{ minWidth: 300 }} fullWidth>
            <InputLabel id="role-select-label" shrink>Policy Role</InputLabel>
            <Select
              disabled={roleLocked}
              labelId="role-select-label"
              id="role-select"
              value={role}
              onChange={handleSelectRole}>
              {appRoles.map((r, x) => (
                <MenuItem value={r} key={x}>{r.name}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <div style={{ marginTop: 24 }}>
            <FormControl component="fieldset" fullWidth>
              <FormLabel component="legend" style={{ fontSize: '0.75rem' }}>Policy Tenant(s)</FormLabel>
              <FormGroup>
                {tenants.map((tenant, x) => (
                  <FormControlLabel
                    key={x}
                    className={classes.clickableListItem}
                    control={(
                      <Checkbox
                        color="primary"
                        disabled={!role}
                        onChange={handleToggleTenant(tenant)}
                        checked={selectedTenantIds.includes(tenant.id)}
                        size="small" />
                    )}
                    label={tenant.name} />
                ))}
              </FormGroup>
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions>
          <Button disabled={saving} onClick={handleClose} color="default">
            Cancel
          </Button>
          <Button
            disabled={saving} onClick={handleSave} color="primary"
            variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <ErrorSnackbar error={error} onDismiss={handleDismissError} />
    </>
  );
}
EditPolicy.propTypes = {
  classes: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  machine: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  policies: PropTypes.array.isRequired,
  selectedPolicy: PropTypes.object,
};

export default withStyles(defaultStyles)(EditPolicy);
