import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { withStyles } from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/Error';

const styles = (theme) => ({
  icon: {
    fontSize: 20,
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    alignItems: 'center',
    display: 'flex',
  },
  outer: {
    backgroundColor: theme.palette.error.dark,
  }
});
const ErrorSnackbar = ({ classes, duration, error, onDismiss }) => {
  const [timer, setTimer] = useState();

  useEffect(() => {
    if (error && duration !== -1) {
      if (!timer) {
        setTimer(
          setTimeout(() => {
            onDismiss();
            setTimer(null);
          }, duration > 0 ? duration : 5000)
        );
      }
    }
  }, [duration, error, onDismiss, timer]);

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      open={Boolean(error)}>
      <SnackbarContent
        className={classes.outer}
        aria-describedby="error-message"
        message={(
          <span id="error-message" className={classes.message}>
            <ErrorIcon className={classes.icon} /> {error}
          </span>
        )} />
    </Snackbar>
  );
};
ErrorSnackbar.propTypes = {
  classes: PropTypes.object.isRequired,
  duration: PropTypes.number,
  error: PropTypes.string,
  onDismiss: PropTypes.func.isRequired
};

export default withStyles(styles)(ErrorSnackbar);
