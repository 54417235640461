import auth from './auth';

export function serializeParams(obj) {
  return Object.keys(obj)
    .map((key) => `${key}=${encodeURIComponent(obj[key])}`)
    .join('&');
}

function url(resource, id, queryParams) {
  if (resource.indexOf('/') !== 0) {
    resource = `/${resource}`;
  }

  let queryString;
  if (queryParams) {
    queryString = serializeParams(queryParams);
  }

  if (queryString) {
    return `${process.env.REACT_APP_API_URL}${resource}${id ? `/${id}/?` : '/?'}${queryString}`;
  }
  return `${process.env.REACT_APP_API_URL}${resource}${id ? `/${id}` : ''}`;
}

function checkStatus(response) {
  if (response.ok) {
    return Promise.resolve(response);
  } else if (response.status === 401) {
    auth.logout(true);
  }
  return Promise.reject(response);
}

function getHeaders(headers = {}) {
  const token = window.localStorage.getItem('access_token') || null;
  const tenant = window.localStorage.getItem('session_tenant') || null;
  return {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    Expires: 'Sat, 01 Jan 2000 00:00:00 GMT',
    Authorization: `Bearer ${token}`,
    'X-Session-Tenant': tenant,
    ...headers,
  };
}

function parseJson(response) {
  return response.json();
}

export function create(resource, data) {
  return fetch(
    url(resource),
    {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify(data),
    }
  )
    .then(checkStatus)
    .then(parseJson);
}

export function read(resource, id, queryParams, options = {}) {
  return fetch(
    url(resource, id, queryParams),
    {
      method: 'GET',
      headers: getHeaders(),
      ...options,
    }
  )
    .then(checkStatus)
    .then(parseJson);
}

export function update(resource, { id, ...data }, fullUpdate = false) {
  return fetch(
    url(resource, id),
    {
      method: fullUpdate ? 'PUT' : 'PATCH',
      headers: getHeaders(),
      body: JSON.stringify(data),
    }
  )
    .then(checkStatus)
    .then(parseJson);
}

export function destroy(resource, id) {
  return fetch(
    url(resource, id),
    {
      method: 'DELETE',
      headers: getHeaders(),
    }
  )
    .then(checkStatus);
}

export default {
  create,
  read,
  update,
  destroy,
};
