import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

export function FullPageLoader() {
  return (
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        padding: 32,
        width: '100%',
      }}>
      <CircularProgress />
    </div>
  );
}

export default FullPageLoader;