import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';

export function Confirm({ confirmButtonTxt, handleCancel, handleConfirm, text }) {
  return (
    <Dialog open={true}>
      <DialogTitle>Are you sure?</DialogTitle>
      {text && (
        <DialogContent>
          <Typography>{text}</Typography>
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button color="secondary" onClick={handleConfirm} variant="contained">{confirmButtonTxt || 'Confirm'}</Button>
      </DialogActions>
    </Dialog>
  );
}
Confirm.propTypes = {
  confirmButtonTxt: PropTypes.string,
  handleCancel: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  text: PropTypes.string,
};

export default Confirm;