import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import ErrorSnackbar from '../shared/ErrorSnackbar';
import { create } from '../services/api';

const userFields = {
  name: 'Full Name',
  email: 'Email Address',
};

const emptyUser = {};
Object.keys(userFields).forEach((field) => emptyUser[field] = '');

export function CreateUser({ handleClose, onCreate }) {
  const [user, setUser] = useState(emptyUser);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState();

  const handleChange = (field) => (event) => {
    setUser({
      ...user,
      [field]: event.target.value
    });
    setError();
  };

  const handleBlur = (field) => () => {
    if (!user[field]) {
      setError('All fields are required.');
    }
  };

  const handleSave = async () => {
    if (!error) {
      setSaving(true);
      create('/users', user).then(
        (newUser) => {
          onCreate(newUser);
        },
        (err) => {
          if (err.message) {
            setError(err.message);
          } else if (err.status === 403) {
            setError('You do not have permission to do that.');
          } else {
            setError('An error has occurred. Please try again.');
          }
          setSaving(false);
        }
      );
    }
  };

  const handleEnter = (event) => {
    if (event.key === 'Enter') {
      handleSave();
    }
  };

  const handleDismissError = () => {
    setError();
  };

  return (
    <>
      <Dialog open={true} onClose={handleClose}>
        <DialogTitle>Create New User</DialogTitle>
        <DialogContent>
          <DialogContentText>
            If you have not already, please try searching for the user you would like
            to add on the underlying page before manually adding them with this form.
          </DialogContentText>
          {Object.keys(userFields).map((field, x) => (
            <TextField
              key={x}
              autoFocus={x === 0}
              fullWidth
              label={userFields[field]}
              margin="dense"
              onBlur={handleBlur(field)}
              onChange={handleChange(field)}
              onKeyPress={handleEnter}
              type="text"
              value={user[field]} />
          ))}
        </DialogContent>
        <DialogActions>
          <Button disabled={saving} onClick={handleClose} color="default">
            Cancel
          </Button>
          <Button
            disabled={saving} onClick={handleSave} color="primary"
            variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <ErrorSnackbar error={error} onDismiss={handleDismissError} />
    </>
  );
}
CreateUser.propTypes = {
  handleClose: PropTypes.func,
  onCreate: PropTypes.func
};

export default CreateUser;
