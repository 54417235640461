import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Fab from '@material-ui/core/Fab';
import InputAdornment from '@material-ui/core/InputAdornment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import { UsersIcon } from '../shared/icons';
import ErrorSnackbar from '../shared/ErrorSnackbar';
import FullPageLoader from '../shared/FullPageLoader';
import { create, read } from '../services/api';
import defaultStyles from '../App.css.js';
import CreateUser from './CreateUser';

export function Users({ classes }) {
  const [creating, setCreating] = useState(false);
  const [error, setError] = useState();
  const [searching, setSearching] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const history = useHistory();

  const handleSearch = useCallback((term) => {
    setSearching(true);
    read('/users', null, { searchTerm: term }).then(
      (results) => {
        setSearchResults(results || []);
        setSearching(false);
      },
      () => {
        setError('Search could not be performed as typed. Please check your search string and try again.');
        setSearchResults([]);
        setSearching(false);
      }
    );
  }, []);

  useEffect(() => {
    handleSearch('');
  }, [handleSearch]);

  const handleSearchTermChange = (event) => {
    const updatedSearch = event.target.value;
    setSearchTerm(updatedSearch);
  };

  const handleSearchEnter = (event) => {
    if (event.key === 'Enter') {
      handleSearch(searchTerm);
    }
  };

  const handleSelectUser = (user) => async () => {
    setSearching(true);
    if (!user.id) {
      const newUser = await create('/users', user);
      if (!newUser) {
        setError('User could not be created. Please refresh the page and try again.');
        setSearching(false);
        return;
      }
      user.id = newUser.id;
    }
    history.push(`/user/${user.id}`);
  };

  const toggleCreationDialog = (which) => () => {
    setCreating(which);
  };
  const onNewUser = (user) => {
    handleSelectUser(user)();
  };

  const handleDismissError = () => {
    setError();
  };

  return (
    <>
      <Fab color="primary" className={classes.fab} onClick={toggleCreationDialog(true)}>
        <AddIcon />
      </Fab>
      {creating && (
        <CreateUser
          handleClose={toggleCreationDialog(false)}
          onCreate={onNewUser} />
      )}
      <ErrorSnackbar error={error} onDismiss={handleDismissError} />

      <div className={classes.titleWithSearch}>
        <Typography variant="h5">User Management</Typography>
        <div className={classes.titleSearchContainer}>
          <TextField
            autoFocus
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start"><SearchIcon /></InputAdornment>
              )
            }}
            label="User Search"
            onChange={handleSearchTermChange}
            onKeyPress={handleSearchEnter}
            size="small"
            value={searchTerm}
            variant="outlined" />
        </div>
      </div>

      <Paper className={classes.mainPaper}>
        {searching && <FullPageLoader />}
        {!searching && (
          <List>
            {searchResults.map((result, x) => (
              <React.Fragment key={x}>
                <ListItem
                  className={classes.clickableListItem}
                  onClick={handleSelectUser(result)}>
                  <ListItemIcon><UsersIcon /></ListItemIcon>
                  <ListItemText primary={result.name} secondary={result.email} />
                </ListItem>
                <Divider />
              </React.Fragment>
            ))}
          </List>
        )}
      </Paper>
    </>
  );
}
Users.propTypes = {
  classes: PropTypes.object
};

export default withStyles(defaultStyles)(Users);
