import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import DataContext from '../contexts/DataContext';
import ErrorSnackbar from '../shared/ErrorSnackbar';
import { create, destroy } from '../services/api';
import defaultStyles from '../App.css.js';

const standardActions = ['create', 'read', 'update', 'delete'];

export function EditPermission({ classes, handleClose, onChange, selectedResource }) {
  const [actionList, setActionList] = useState([]);
  const [appPermissions, setAppPermissions] = useState([]);
  const [selectedActions, setSelectedActions] = useState([]);
  const [newAction, setNewAction] = useState('');
  const [resource, setResource] = useState('');
  const [resourceLocked, setResourceLocked] = useState(false);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState();
  const { permissions, refreshPermissions, sessionApplication } = useContext(DataContext);

  useEffect(() => {
    setAppPermissions(permissions[sessionApplication.id] || []);
  }, [permissions, sessionApplication]);

  useEffect(() => {
    const resource = selectedResource || '';
    setResource(resource);
    setResourceLocked(!!resource);

    const actionNames = (appPermissions[resource] || []).map((p) => p.action);
    setSelectedActions(actionNames);

    // all actions
    const extraActions = actionNames.filter((action) => !standardActions.includes(action));
    setActionList([
      ...standardActions,
      ...extraActions
    ]);
  }, [appPermissions, selectedResource]);

  const handleTextChange = (field) => (event) => {
    const newValue = event.target.value;
    if (field === 'resource') {
      setResource(newValue);

      // case-insensitive search resource search
      const existingResource = Object.keys(appPermissions).find((r) => r.toLowerCase() === newValue.toLowerCase());
      if (appPermissions[existingResource]) {
        const actionNames = appPermissions[existingResource].map((p) => p.action);
        setSelectedActions(actionNames);
      } else {
        setSelectedActions([]);
      }
    } else {
      setNewAction(newValue);
    }
    setError();
  };

  const handleResourceBlur = () => {
    if (resource) {
      const existingResource = Object.keys(appPermissions).find((r) => r.toLowerCase() === resource.toLowerCase());
      if (existingResource) {
        setResource(existingResource);
      }
      setResourceLocked(true);
    } else {
      setError('A resource name is required.');
    }
  };

  const handleActionToggle = (action) => () => {
    if (resourceLocked) {
      if (selectedActions.includes(action)) {
        setSelectedActions(selectedActions.filter((a) => a !== action));
      } else {
        setSelectedActions([...selectedActions, action]);
      }
    }
    setError();
  };

  const handleActionAdd = () => {
    const cleanedNewAction = newAction.trim();
    if (cleanedNewAction) {
      if (actionList.find((a) => a.toLowerCase() === cleanedNewAction.toLowerCase())) {
        setError('An action with that name already exists.');
      } else {
        setActionList([...actionList, cleanedNewAction]);
        setSelectedActions([...selectedActions, cleanedNewAction]);
        setNewAction('');
        setError();
      }
    }
  };

  const handleNewActionEnter = (event) => {
    if (event.key === 'Enter') {
      handleActionAdd();
    }
  };

  const handleSave = async () => {
    if (!error) {
      if (resource) {
        const originalActions = appPermissions[resource] || [];
        const createActions = selectedActions.filter((name) => !originalActions.find((a) => a.action === name));
        const deleteActions = originalActions.filter((a) => !selectedActions.find((name) => a.action === name));
        if (createActions.length > 0 || deleteActions.length > 0) {
          setSaving(true);
          Promise.all([
            Promise.all(
              createActions.map((action) => create('/permissions', {
                application: sessionApplication.id,
                resource,
                action
              }))
            ),
            Promise.all(
              deleteActions.map((action) => destroy('/permissions', action.id))
            )
          ]).then(
            () => {
              onChange();
              handleClose();
            },
            (err) => {
              if (err.message) {
                setError(err.message);
              } else if (err.status === 403) {
                setError('You do not have permission to do that.');
              } else if (deleteActions.length > 0) {
                try {
                  const errOnId = err.url.split('/').pop();
                  const errOnPerm = originalActions.find((perm) => perm.id === errOnId);
                  if (!errOnPerm) throw new Error();
                  setError(
                    `${errOnPerm.action}:${resource} could not be deleted, `
                    + 'probably because it is still assigned to at least one role.'
                  );
                } catch {
                  setError('An error has occurred. Please try again.');
                }
              } else {
                setError('An error has occurred. Please try again.');
              }

              // make sure up to date on new state (may not have failed on all changes)
              refreshPermissions();
              setSaving(false);
            }
          );
        } else {
          setError('At least one change is required.');
        }
      } else {
        setError('A resource name is required.');
      }
    }
  };

  const handleDismissError = () => {
    setError();
  };

  return (
    <>
      <Dialog open={true} onClose={handleClose}>
        <DialogTitle>Edit Permissions</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus={!resourceLocked}
            disabled={resourceLocked}
            fullWidth
            InputProps={{
              style: resourceLocked
                ? { color: 'rgba(0,0,0,0.7)', fontWeight: 'bold' }
                : {}
            }}
            label="Resource"
            margin="dense"
            onBlur={handleResourceBlur}
            onChange={handleTextChange('resource')}
            style={{ minWidth: 400 }}
            type="text"
            value={resource} />

          <List dense>
            {actionList.map((action, x) => {
              return (
                <React.Fragment key={x}>
                  {x === 4 && <Divider />}
                  <ListItem
                    className={classes.clickableListItem}
                    disableGutters
                    onClick={handleActionToggle(action)}
                    style={{ cursor: resource ? 'pointer' : 'initial' }}>
                    <ListItemIcon className={classes.miniCheckboxListIcon}>
                      <Checkbox
                        className={classes.miniCheckbox}
                        color="primary"
                        disabled={!resource}
                        checked={selectedActions.includes(action)} />
                    </ListItemIcon>
                    <ListItemText primary={action} />
                  </ListItem>
                </React.Fragment>
              );
            })}
            <ListItem disableGutters>
              <ListItemIcon className={classes.miniCheckboxListIcon}>
                <Checkbox
                  checked={false}
                  className={classes.miniCheckbox}
                  disabled={!newAction}
                  color="primary"
                  onClick={handleActionAdd}
                  style={{ marginTop: -7 }} />
              </ListItemIcon>
              <ListItemText
                primary={(
                  <TextField
                    disabled={!resource}
                    fullWidth
                    InputProps={{ style: { fontSize: '0.875rem' } }}
                    margin="dense"
                    onChange={handleTextChange('action')}
                    onKeyPress={handleNewActionEnter}
                    placeholder="Add Custom Action..."
                    size="small"
                    style={{ margin: 0 }}
                    type="text"
                    value={newAction} />
                )} />
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button disabled={saving} onClick={handleClose} color="default">
            Cancel
          </Button>
          <Button
            disabled={saving} onClick={handleSave} color="primary"
            variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <ErrorSnackbar error={error} onDismiss={handleDismissError} />
    </>
  );
}
EditPermission.propTypes = {
  classes: PropTypes.object,
  handleClose: PropTypes.func,
  onChange: PropTypes.func,
  selectedResource: PropTypes.string
};

export default withStyles(defaultStyles)(EditPermission);