import auth0 from 'auth0-js';
import logger from './logger';

class Auth {
  constructor() {
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.authorizing = false;
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isLoggedIn = this.isLoggedIn.bind(this);
    this.auth0 = new auth0.WebAuth({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
      domain: process.env.REACT_APP_AUTH0_DOMAIN,
      responseType: 'id_token token',
      scope: 'openid profile'
    });
  }

  login() {
    if (!this.authorizing) {
      this.authorizing = true;

      const next = `${window.location.pathname}${window.location.search}`;
      this.auth0.authorize({
        connection: null, // this forces login prompt (remove to auto-reauth when possible)
        redirectUri: `${window.location.origin}/login?next=${encodeURIComponent(next)}`
      });
    }
  }

  handleAuthentication() {
    this.authorizing = false;

    return new Promise((resolve, reject) => {
      this.auth0.parseHash(async (err, authResult) => {
        if (authResult && authResult.accessToken && authResult.idToken) {
          localStorage.setItem('access_token', authResult.accessToken);
          localStorage.setItem('auth_expires', Date.now() + (authResult.expiresIn * 1000));
          localStorage.setItem('id_token', authResult.idToken);
          localStorage.setItem('user', JSON.stringify(
            authResult.idTokenPayload ? {
              providerId: authResult.idTokenPayload.sub,
              nickname: authResult.idTokenPayload.nickname,
              name: authResult.idTokenPayload.name,
              picture: authResult.idTokenPayload.picture,
            } : {}
          ));
          return resolve(this.getNext());
        } 
        return reject(err || 'Could not parse auth code.');
      });
    });
  }

  async logout(retry) {
    this.authorizing = false;

    localStorage.clear();
    sessionStorage.clear();
    if (retry) {
      this.login();
    } else {
      await this.auth0.logout({
        returnTo: window.location.origin
      });
    }
  }

  getNext() {
    const url = new URL(window.location.href);
    const next = url.searchParams.has('next') ? url.searchParams.get('next') : null;
    if (next && !/^\/log(in|out)\b/.test(next)) {
      return next;
    }
    return '/';
  }

  isLoggedIn() {
    const token = localStorage.getItem('access_token');
    const idToken = localStorage.getItem('id_token');
    const user = localStorage.getItem('user');
    const expires = localStorage.getItem('auth_expires');
    return (token && idToken && user && expires && expires > Date.now());
  }

  getUserInfo() {
    const user = localStorage.getItem('user');
    if (user) {
      try {
        return JSON.parse(user);
      } catch {
        logger.error('localStorage.user not valid JSON');
        this.logout();
      }
    }
    return null;
  }
}

export default new Auth();
