import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { create, update } from '../services/api';
import ErrorSnackbar from '../shared/ErrorSnackbar';

export function EditTenant({ handleClose, onChange, selectedTenant, tenants }) {
  const creating = !(selectedTenant && selectedTenant.id);

  const [name, setName] = useState(creating ? '' : selectedTenant.name);
  const [description, setDescription] = useState(creating ? '' : selectedTenant.description);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState();

  const handleChange = (field) => (event) => {
    const updatedValue = event.target.value;
    if (field === 'name') {
      setName(updatedValue);

      // light validation
      if (!updatedValue) {
        setError('A tenant name is required.');
      } else if (tenants.find((t) => t.name.toLowerCase() === updatedValue.toLowerCase().trim())) {
        setError('A tenant already exists with that name.');
      } else {
        setError();
      }
    } else if (field === 'description') {
      setDescription(updatedValue);
    }
  };
  const handleSave = () => {
    if (name && !error) {
      setSaving(true);
      (creating ? create : update)('/tenants', {
        id: creating ? undefined : selectedTenant.id,
        name,
        description,
      }).then(
        () => {
          if (onChange) {
            onChange();
          }
          handleClose();
        },
        (err) => {
          if (err.message) {
            setError(err.message);
          } else if (err.status === 403) {
            setError('You do not have permission to do that.');
          } else {
            setError('An error has occurred. Please try again.');
          }
          setSaving(false);
        }
      );
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSave();
    }
  };

  const handleDismissError = () => {
    setError();
  };

  return (
    <>
      <Dialog open={true} onClose={handleClose}>
        <DialogTitle>{creating ? 'Create New' : 'Edit'} Tenant</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            disabled={!creating}
            fullWidth
            label="Tenant Label"
            margin="dense"
            onChange={handleChange('name')}
            onKeyPress={handleKeyPress}
            style={{ minWidth: 400 }}
            type="text"
            value={name} />

          <TextField
            fullWidth
            label="Tenant Description"
            margin="dense"
            onChange={handleChange('description')}
            onKeyPress={handleKeyPress}
            style={{ minWidth: 400 }}
            type="text"
            value={description} />
        </DialogContent>
        <DialogActions>
          <Button disabled={saving} onClick={handleClose} color="default">
            Cancel
          </Button>
          <Button
            disabled={saving} onClick={handleSave} color="primary"
            variant="contained">
            Create
          </Button>
        </DialogActions>
      </Dialog>
      <ErrorSnackbar error={error} onDismiss={handleDismissError} />
    </>
  );
}
EditTenant.propTypes = {
  handleClose: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  selectedTenant: PropTypes.object,
  tenants: PropTypes.array.isRequired
};

export default EditTenant;