import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import InputAdornment from '@material-ui/core/InputAdornment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import SearchIcon from '@material-ui/icons/Search';
import { MachinesIcon } from '../shared/icons';
import ErrorSnackbar from '../shared/ErrorSnackbar';
import FullPageLoader from '../shared/FullPageLoader';
import { create, read } from '../services/api';
import defaultStyles from '../App.css.js';

export function Machines({ classes }) {
  const [error, setError] = useState();
  const [searching, setSearching] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const history = useHistory();

  const handleSearch = useCallback((term) => {
    setSearching(true);
    read('/machines', null, { searchTerm: term }).then(
      (results) => {
        setSearchResults(results || []);
        setSearching(false);
      },
      () => {
        setError('Search could not be performed as typed. Please check your search string and try again.');
        setSearchResults([]);
        setSearching(false);
      }
    );
  }, []);

  useEffect(() => {
    handleSearch('');
  }, [handleSearch]);

  const handleSearchTermChange = (event) => {
    const updatedSearch = event.target.value;
    setSearchTerm(updatedSearch);
  };

  const handleSearchEnter = (event) => {
    if (event.key === 'Enter') {
      handleSearch(searchTerm);
    }
  };

  const handleSelectMachine = (machine) => async () => {
    setSearching(true);
    if (!machine.id) {
      const newMachine = await create('/machines', machine);
      if (!newMachine) {
        setError('Machine could not be created. Please refresh the page and try again.');
        setSearching(false);
        return;
      }
      machine.id = newMachine.id;
    }
    history.push(`/machine/${machine.id}`);
  };

  const handleDismissError = () => {
    setError();
  };

  return (
    <>
      <ErrorSnackbar error={error} onDismiss={handleDismissError} />

      <div className={classes.titleWithSearch}>
        <Typography variant="h5">Machine Management</Typography>
        <div className={classes.titleSearchContainer}>
          <TextField
            autoFocus
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start"><SearchIcon /></InputAdornment>
              )
            }}
            label="Machine Search"
            onChange={handleSearchTermChange}
            onKeyPress={handleSearchEnter}
            size="small"
            value={searchTerm}
            variant="outlined" />
        </div>
      </div>

      <Paper className={classes.mainPaper}>
        {searching && <FullPageLoader />}
        {!searching && (
          <List>
            {searchResults.map((result, x) => (
              <React.Fragment key={x}>
                <ListItem
                  className={classes.clickableListItem}
                  onClick={handleSelectMachine(result)}>
                  <ListItemIcon><MachinesIcon /></ListItemIcon>
                  <ListItemText
                    primary={(
                      <>
                        {result.name}
                        <span className={classes.listItemPrimaryExtra}>
                          {result.id || '(machine id not yet generated)'}
                        </span>
                      </>
                    )}
                    secondary={`Auth0 Client ID: ${result.clientId}`} />
                </ListItem>
                <Divider />
              </React.Fragment>
            ))}
          </List>
        )}
      </Paper>
    </>
  );
}
Machines.propTypes = {
  classes: PropTypes.object
};

export default withStyles(defaultStyles)(Machines);
