import React, { useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Fab from '@material-ui/core/Fab';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DataContext from '../contexts/DataContext';
import ApplicationSelector from '../shared/ApplicationSelector';
import { PermissionsIcon } from '../shared/icons';
import FullPageLoader from '../shared/FullPageLoader';
import defaultStyles from '../App.css.js';
import EditPermission from './EditPermission';

export function Permissions({ classes }) {
  const [loading, setLoading] = useState(true);
  const [creatingResource, setCreatingResource] = useState();
  const [editingResource, setEditingResource] = useState();
  const { permissions, refreshPermissions, sessionApplication } = useContext(DataContext);

  const refreshList = useCallback(async () => {
    setLoading(true);
    await refreshPermissions();
    setLoading(false);
  }, [setLoading, refreshPermissions]);

  useEffect(() => {
    refreshList();
  }, [refreshList]);

  const handleCreateResource = () => {
    setCreatingResource(true);
  };
  const handleEditResource = (resource) => () => {
    setEditingResource(resource);
  };
  const handleDialogClose = () => {
    setCreatingResource(false);
    setEditingResource();
  };

  if (loading) {
    return <FullPageLoader />;
  }

  const appPermissions = permissions[sessionApplication.id] || {};
  const resources = Object.keys(appPermissions || {}).sort((a, b) => a.toLowerCase() < b.toLowerCase() ? -1 : 1);
  return (
    <>
      <Fab color="primary" className={classes.fab} onClick={handleCreateResource}>
        <AddIcon />
      </Fab>
      {(creatingResource || editingResource) && (
        <EditPermission
          handleClose={handleDialogClose}
          onChange={refreshList}
          selectedResource={editingResource} />
      )}

      <div className={classes.titleWithSelector}>
        <Typography variant="h5">Permission Management</Typography>
        <div style={{ flex: 1 }} />
        <ApplicationSelector />
      </div>

      <Paper className={classes.mainPaper}>
        {resources.length === 0
          ? (
            <Typography className={classes.na}>No permissions have been created for this application.</Typography>
          )
          : (
            <List>
              {resources.map((resource, index) => (
                <React.Fragment key={index}>
                  <ListItem
                    className={classes.clickableListItem}
                    onClick={handleEditResource(resource)}>
                    <ListItemIcon><PermissionsIcon /></ListItemIcon>
                    <ListItemText
                      primary={resource}
                      secondary={appPermissions[resource].map((p) => p.action).join(', ')} />
                    <ListItemSecondaryAction>
                      <IconButton onClick={handleEditResource(resource)}>
                        <EditIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                </React.Fragment>
              ))}
            </List>
          )
        }
      </Paper>
    </>
  );
}
Permissions.propTypes = {
  classes: PropTypes.object
};

export default withStyles(defaultStyles)(Permissions);