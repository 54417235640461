import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import auth from '../services/auth';

export function Logout() {
  const history = useHistory();

  useEffect(() => {
    auth.logout().catch(() => {
      history.replace('/');
    });
  }, [history]);

  return null;
}

export default Logout;
