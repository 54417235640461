import React, { useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Fab from '@material-ui/core/Fab';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import DataContext from '../contexts/DataContext';
import { read, destroy } from '../services/api';
import logger from '../services/logger';
import { HooksIcon } from '../shared/icons';
import Confirm from '../shared/Confirm';
import ErrorSnackbar from '../shared/ErrorSnackbar';
import FullPageLoader from '../shared/FullPageLoader';
import defaultStyles from '../App.css.js';
import EditHook from './EditHook';

export function Hooks({ classes }) {
  const [loading, setLoading] = useState(true);
  const [hooks, setHooks] = useState([]);
  const [creatingHook, setCreatingHook] = useState();
  const [editingHook, setEditingHook] = useState();
  const [deleting, setDeleting] = useState(false);
  const [error, setError] = useState();
  const { applications, roles } = useContext(DataContext);

  const refreshList = useCallback(async () => {
    setLoading(true);
    if (roles) {
      read('/hooks', null, { type: 'userpolicy' }).then((results) => {
        setHooks(
          (results || [])
            .map((hook) => ({
              ...hook,
              policies: hook.policies.map((p) => ({
                application: ((roles || []).find(({ id }) => id === p.role.id) || {}).application,
                role: p.role.id,
                tenant: p.tenant.id
              }))
            }))
            .sort((a, b) => a.name < b.name ? -1 : 1)
        );
        setLoading(false);
      });
    }
  }, [roles]);

  useEffect(() => {
    refreshList();
  }, [refreshList]);

  const handleCreateHook = () => {
    setCreatingHook(true);
  };
  const handleEditHook = (hook) => () => {
    setEditingHook(hook);
  };
  const handleDialogClose = () => {
    setCreatingHook(false);
    setEditingHook();
  };

  const handleDeleteHook = (hook) => () => {
    setDeleting(hook);
  };
  const handleDeleteCancel = () => {
    setDeleting();
  };
  const handleDeleteConfirm = () => {
    destroy('/hooks', deleting.id).then(() => {
      setDeleting();
      refreshList();
    }, (err) => {
      logger.error(err);
      setDeleting();
      setError(`The ${deleting.name} role could not be deleted.`);
    });
  };

  const getDescription = (hook) => {
    if (hook.description) {
      return hook.description;
    }

    // default description
    const appTallies = {};
    hook.policies.forEach(({ application }) => {
      if (!appTallies[application]) {
        appTallies[application] = {
          name: (applications.find((a) => a.id === application) || {}).name,
          tally: 0
        };
      }
      appTallies[application].tally++;
    });
    return Object.values(appTallies)
      .map(({ name, tally }) => `${tally} polic${tally === 1 ? 'y' : 'ies'} on ${name}`)
      .join(', ');
  };

  const handleDismissError = () => {
    setError();
  };

  if (loading) {
    return <FullPageLoader />;
  }

  return (
    <>
      <Fab color="primary" className={classes.fab} onClick={handleCreateHook}>
        <AddIcon />
      </Fab>
      {(creatingHook || editingHook) && (
        <EditHook
          allHooks={hooks}
          handleClose={handleDialogClose}
          onChange={refreshList}
          selectedHook={editingHook} />
      )}
      {deleting && (
        <Confirm
          confirmButtonTxt="Delete"
          text={`Are you sure you want to delete the "${deleting.name}" hook? This cannot be undone.`}
          handleConfirm={handleDeleteConfirm}
          handleCancel={handleDeleteCancel} />
      )}
      <ErrorSnackbar error={error} onDismiss={handleDismissError} />

      <div className={classes.titleWithSelector}>
        <Typography variant="h5">Hook Management</Typography>
      </div>

      <Paper className={classes.mainPaper}>
        {hooks.length === 0
          ? (
            <Typography className={classes.na}>No hooks have been created yet.</Typography>
          )
          : (
            <List>
              {hooks.map((hook, index) => (
                <React.Fragment key={index}>
                  <ListItem
                    className={classes.clickableListItem}
                    onClick={handleEditHook(hook)}>
                    <ListItemIcon><HooksIcon /></ListItemIcon>
                    <ListItemText
                      primary={hook.name}
                      secondary={getDescription(hook)} />
                    <ListItemSecondaryAction>
                      <IconButton
                        className={classes.subtleDeleteButton}
                        color="secondary"
                        onClick={handleDeleteHook(hook)}>
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                </React.Fragment>
              ))}
            </List>
          )
        }
      </Paper>
    </>
  );
}
Hooks.propTypes = {
  classes: PropTypes.object
};

export default withStyles(defaultStyles)(Hooks);