export default {
  overrides: {},
  palette: {
    primary: {
      dark: '#01467f',
      main: '#0093d0'
    },
    secondary: {
      main: '#ef2f23'
    }
  },
};
