const drawerWidth = 240;

export default (theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  fab: {
    bottom: theme.spacing(2),
    position: 'fixed',
    right: theme.spacing(2),
    zIndex: 1
  },
  clickableListItem: {
    cursor: 'pointer',
    '&:hover': {
      background: 'rgba(0,0,0,0.03)'
    }
  },
  hoverBackground: {
    '&:hover': {
      background: 'rgba(0,0,0,0.03)'
    }
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    minHeight: '100vh',
    padding: theme.spacing(3),
  },
  mainPaper: {
    flex: 1,
    padding: `0 ${theme.spacing(2)}px`,
    marginTop: theme.spacing(),
  },
  miniCheckbox: {
    padding: 0
  },
  miniCheckboxListIcon: {
    minWidth: 34
  },
  na: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    opacity: 0.5,
    width: '100%',
  },
  listItemPrimaryExtra: {
    fontSize: '75%',
    opacity: 0.5,
    marginLeft: theme.spacing(1)
  },
  root: {
    display: 'flex'
  },
  titleOnly: {
    alignItems: 'flex-end',
    display: 'flex',
    height: 40
  },
  titleSearchContainer: {
    flex: 1,
    paddingLeft: theme.spacing(2)
  },
  titleWithSearch: {
    alignItems: 'flex-end',
    display: 'flex',
  },
  titleWithSelector: {
    alignItems: 'flex-end',
    display: 'flex',
  },
  toolbar: theme.mixins.toolbar,
  subtleDeleteButton: {
    '& > span': {
      color: 'rgba(0, 0, 0, 0.3)',
    },
    '&:hover > span': {
      color: 'inherit'
    }
  },
  visiblePrimaryIconButton: {
    backgroundColor: 'rgba(0, 147, 208, 0.05)'
  }
});