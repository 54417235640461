import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { read } from '../services/api';
import FullPageLoader from '../shared/FullPageLoader';

const DataContext = React.createContext();
export default DataContext;

export function DataProvider({ children }) {
  const [applications, setApplications] = useState();
  const [initialDataLoaded, setInitialDataLoaded] = useState(false);
  const [permissions, setPermissions] = useState();
  const [roles, setRoles] = useState();
  const [sessionApplication, setSessionApplication] = useState();
  const [tenants, setTenants] = useState();

  const refreshApplications = useCallback(async () => {
    const applications = ((await read('applications')) || [])
      .sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1);
    await setApplications(applications);

    let app = undefined;
    if (applications.length > 0) {
      app = applications[0];
      const persistedAppId = sessionStorage.getItem('selectedApplicationId');
      if (persistedAppId) {
        const persistedApp = applications.find(({ id }) => id === persistedAppId);
        if (persistedApp) {
          app = persistedApp;
        }
      }
    }
    await setSessionApplication(app);
  }, []);

  const refreshPermissions = useCallback(async () => {
    const bucketedPermissions = {};
    ((await read('permissions')) || [])
      .sort((a, b) => {
        if (a.resource === b.resource) {
          return a.action.toLowerCase() < b.action.toLowerCase() ? -1 : 1;
        }
        return a.resource.toLowerCase() < b.resource.toLowerCase() ? -1 : 1;
      })
      .forEach((p) => {
        if (!bucketedPermissions[p.application]) {
          bucketedPermissions[p.application] = {};
        }
        if (!bucketedPermissions[p.application][p.resource]) {
          bucketedPermissions[p.application][p.resource] = [];
        }
        bucketedPermissions[p.application][p.resource].push({ 
          id: p.id,
          action: p.action
        });
      });
    await setPermissions(bucketedPermissions);
  }, []);

  const refreshRoles = useCallback(async () => {
    await setRoles(
      ((await read('roles')) || [])
        .sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1)
    );
  }, []);
  
  const refreshTenants = useCallback(async () => {
    await setTenants(
      ((await read('tenants')) || [])
        .sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1)
    );
  }, []);

  const refreshAllData = useCallback(async () => {
    Promise.all([
      refreshApplications(),
      refreshPermissions(),
      refreshRoles(),
      refreshTenants()
    ]).then(() => {
      setInitialDataLoaded(true);
    });
  }, [refreshApplications, refreshPermissions, refreshRoles, refreshTenants]);

  // load all data when first rendered
  useEffect(() => { refreshAllData(); }, [refreshAllData]);

  if (initialDataLoaded) {
    return (
      <DataContext.Provider
        value={{
          applications,
          permissions,
          roles,
          tenants,
          refreshApplications,
          refreshPermissions,
          refreshRoles,
          refreshTenants,
          sessionApplication,
          setApplications,
          setPermissions,
          setRoles,
          setTenants,
          setSessionApplication,
        }}>
        {children}
      </DataContext.Provider>
    );
  }

  return <FullPageLoader />;
}
DataProvider.propTypes = {
  children: PropTypes.node
};
