import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import {
  ApplicationsIcon,
  HooksIcon,
  MachinesIcon,
  PermissionsIcon,
  RolesIcon,
  TenantsIcon,
  UsersIcon
} from '../shared/icons';
import AuthContext from '../contexts/AuthContext';
import styles from '../App.css.js';

const navItem = (path, label, icon, history) => {
  const selected = (path === history.location.pathname);
  return (
    <ListItem 
      button
      selected={selected}
      onClick={() => history.push(path)}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={label} />
    </ListItem>
  );
};

export function Sidebar({ classes }) {
  const history = useHistory();
  const { ability } = useContext(AuthContext);
  return (
    <Drawer
      className={classes.drawer}
      classes={{ paper: classes.drawerPaper }}
      variant="permanent">
      <div className={classes.toolbar} />
      <List>
        {ability.can('create', 'userPolicies') && navItem('/users', 'Users', <UsersIcon />, history)}
        {ability.can('create', 'machinePolicies') && navItem('/machines', 'Machines', <MachinesIcon />, history)}
        {ability.can('create', 'hooks') && navItem('/hooks', 'Hooks', <HooksIcon />, history)}
        <Divider />
        {ability.can('create', 'applications') && 
          navItem('/applications', 'Applications', <ApplicationsIcon />, history)}
        {ability.can('create', 'permissions') && navItem('/permissions', 'Permissions', <PermissionsIcon />, history)}
        {ability.can('create', 'roles') && navItem('/roles', 'Roles', <RolesIcon />, history)}
        {ability.can('create', 'tenants') && navItem('/tenants', 'Tenants', <TenantsIcon />, history)}
      </List>
    </Drawer>
  );
}
Sidebar.propTypes = {
  classes: PropTypes.object
};

export default withStyles(styles)(Sidebar);