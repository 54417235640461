import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Applications from './applications/Applications';
import Hooks from './hooks/Hooks';
import Logout from './auth/Logout';
import Machine from './machines/Machine';
import Machines from './machines/Machines';
import Permissions from './permissions/Permissions';
import Roles from './roles/Roles';
import Tenants from './tenants/Tenants';
import User from './users/User';
import Users from './users/Users';

export function AuthenticatedRoutes() {
  return (
    <Switch>
      <Redirect exact from="/" to="/users"  />
      <Route path="/applications" component={Applications} />
      <Route path="/hooks" component={Hooks} />
      <Route path="/logout" component={Logout} />
      <Route path="/machines" component={Machines} />
      <Route path="/machine/:id" component={Machine} />
      <Route path="/permissions" component={Permissions} />
      <Route path="/roles" component={Roles} />
      <Route path="/tenants" component={Tenants} />
      <Route path="/users" component={Users} />
      <Route path="/user/:id" component={User} />
    </Switch>
  );
}

export default AuthenticatedRoutes;