import React, { useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import DataContext from '../contexts/DataContext';
import ErrorSnackbar from '../shared/ErrorSnackbar';
import { update, create } from '../services/api';
import defaultStyles from '../App.css.js';

const emptyHook = {
  name: '',
  description: '',
  condition: {
    field: '',
    pattern: '',
  },
  policies: []
};

export function EditHook({ classes, handleClose, onChange, selectedHook, allHooks }) {
  const [hook, setHook] = useState(cloneDeep(selectedHook || emptyHook));
  const [selectedPolicies, setSelectedPolicies] = useState(
    (selectedHook || emptyHook).policies.map((p) => JSON.stringify(p))
  );
  const [bucketedPolicies, setBucketedPolicies] = useState([]);
  const [newPolicy, setNewPolicy] = useState({ application: '', role: '', tenant: '' });
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState();
  const { applications, roles, tenants } = useContext(DataContext);

  const bucketPolicies = useCallback((policies) => {
    const buckets = {};
    policies.forEach((p) => {
      if (!buckets[p.application]) {
        buckets[p.application] = {
          name: (applications.find(({ id }) => id === p.application) || {}).name,
          policies: []
        };
      }
      buckets[p.application].policies.push(p);
    });
    setBucketedPolicies(
      Object.values(buckets).sort((a, b) => a.name < b.name ? -1 : 1)
    );
  }, [applications]);

  useEffect(() => {
    bucketPolicies(selectedHook ? selectedHook.policies : []);
  }, [bucketPolicies, selectedHook]);

  const handleTextChange = (field) => (event) => {
    const newHook = { ...hook };
    set(newHook, field, event.target.value);

    setHook(newHook);
    setError();
  };

  const handlePolicyToggle = (policy) => () => {
    const jPolicy = JSON.stringify(policy);
    if (selectedPolicies.includes(jPolicy)) {
      setSelectedPolicies(selectedPolicies.filter((p) => p !== jPolicy));
    } else {
      setSelectedPolicies([...selectedPolicies, jPolicy]);
    }
    setError();
  };

  const handleNewPolicyChange = (field) => (event) => {
    const newValue = event.target.value;
    if (field === 'application' && newPolicy.application !== newValue) {
      setNewPolicy({
        application: newValue,
        role: '',
        tenant: ''
      });
    } else {
      setNewPolicy({
        ...newPolicy,
        [field]: newValue
      });
    }
    setError();
  };

  const handleNewPolicyAdd = () => {
    if (newPolicy.role && newPolicy.tenant) {
      if (hook.policies.find((p) => p.role === newPolicy.role && p.tenant === newPolicy.tenant)) {
        setError('An identical policy already exists.');
      } else {
        const newPolicies = [...hook.policies, newPolicy];
        setHook({
          ...hook,
          policies: newPolicies
        });
        bucketPolicies(newPolicies);
        handlePolicyToggle(newPolicy)();
        setNewPolicy({ application: '', role: '', tenant: '' });
        setError();
      }
    } else {
      setError('Specify a role and tenant first.');
    }
  };

  const handleSave = async () => {
    if (!error) {
      const saveHook = {
        ...hook,
        policies: hook.policies.filter((p) => selectedPolicies.includes(JSON.stringify(p))),
        type: 'UserPolicyHook'
      };
      const nameChanged = saveHook.name !== (selectedHook || {}).name;
      if (JSON.stringify(selectedHook) === JSON.stringify(saveHook)) {
        setError('At least one change is required.');
      } else if (!(saveHook.name && saveHook.condition.field && saveHook.condition.pattern)) {
        setError('Name and condition fields are required.');
      } else if (saveHook.policies.length === 0) {
        setError('No policies are currently selected.');
      } else if (nameChanged && allHooks.find((other) => other.name.toLowerCase() === saveHook.name.toLowerCase())) {
        setError('A hook with that name already exists.');
      } else {
        const saveFunc = saveHook.id ? update : create;
        setSaving(true);
        saveFunc('/hooks', saveHook).then(
          () => {
            onChange();
            handleClose();
          },
          (err) => {
            if (err.message) {
              setError(err.message);
            } else if (err.status === 403) {
              setError('You do not have permission to do that.');
            } else {
              setError('An error has occurred. Please try again.');
            }
            setSaving(false);
          }
        );
      }
    }
  };

  const getRoleName = (roleId) => {
    return (roles.find(({ id }) => id === roleId) || {}).name;
  };
  const getTenantName = (tenantId) => {
    return (tenants.find(({ id }) => id === tenantId) || {}).name;
  };

  const handleDismissError = () => {
    setError();
  };

  const inputProps = {
    fullWidth: true,
    margin: 'dense',
    type: 'text'
  };

  return (
    <>
      <Dialog
        open={true} onClose={handleClose} maxWidth="sm"
        fullWidth>
        <DialogTitle>{selectedHook ? 'Edit' : 'Create New'} Hook</DialogTitle>
        <DialogContent>
          <TextField
            {...inputProps}
            autoFocus={!hook.name}
            label="Name"
            onChange={handleTextChange('name')}
            value={hook.name} />

          <TextField
            {...inputProps}
            label="Description"
            onChange={handleTextChange('description')}
            value={hook.description} />

          <div style={{ display: 'flex' }}>
            <TextField
              {...inputProps}
              label="Condition Field"
              onChange={handleTextChange('condition.field')}
              value={hook.condition.field} />
            
            <div style={{ width: 8 }} />

            <TextField
              {...inputProps}
              label="Condition Pattern"
              onChange={handleTextChange('condition.pattern')}
              value={hook.condition.pattern} />
          </div>

          <List dense>
            {bucketedPolicies.map(({ name, policies }, x) => (
              <React.Fragment key={x}>
                <Typography variant="button" style={{ fontWeight: 'bold' }}>
                  {name}
                </Typography>
                {policies.map((policy, y) => (
                  <ListItem
                    key={y}
                    className={classes.clickableListItem}
                    disableGutters
                    onClick={handlePolicyToggle(policy)}
                    style={{ cursor: 'pointer' }}>
                    <ListItemIcon className={classes.miniCheckboxListIcon}>
                      <Checkbox
                        className={classes.miniCheckbox}
                        color="primary"
                        checked={selectedPolicies.includes(JSON.stringify(policy))} />
                    </ListItemIcon>
                    <ListItemText
                      primary={getRoleName(policy.role)}
                      secondary={getTenantName(policy.tenant)} />
                  </ListItem>
                ))}
              </React.Fragment>
            ))}

            <Typography variant="button" style={{ fontWeight: 'bold' }}>
              (Add Policy)
            </Typography>
            <ListItem disableGutters>
              <ListItemIcon className={classes.miniCheckboxListIcon} style={{ marginTop: 8 }}>
                <Checkbox
                  checked={false}
                  className={classes.miniCheckbox}
                  disabled={!(newPolicy.role && newPolicy.tenant)}
                  color="primary"
                  onClick={handleNewPolicyAdd} />
              </ListItemIcon>
              <ListItemText
                disableTypography
                primary={(
                  <div style={{ display: 'flex', marginTop: -8 }}>
                    <FormControl fullWidth>
                      <InputLabel id="role-select-label">Application</InputLabel>
                      <Select
                        labelId="application-select-label"
                        id="application-select"
                        value={newPolicy.application}
                        onChange={handleNewPolicyChange('application')}>
                        {applications.map((application, key) => (
                          <MenuItem key={key} value={application.id}>{application.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <div style={{ width: 8 }} />
                    <FormControl fullWidth>
                      <InputLabel id="role-select-label">Role</InputLabel>
                      <Select
                        labelId="role-select-label"
                        id="role-select"
                        value={newPolicy.role}
                        onChange={handleNewPolicyChange('role')}>
                        {
                          roles
                            .filter(({ application }) => application === newPolicy.application)
                            .map((role, key) => (
                              <MenuItem key={key} value={role.id}>{role.name}</MenuItem>
                            ))
                        }
                      </Select>
                    </FormControl>
                    <div style={{ width: 8 }} />
                    <FormControl fullWidth>
                      <InputLabel id="tenant-select-label">Tenant</InputLabel>
                      <Select
                        labelId="tenant-select-label"
                        id="tenant-select"
                        value={newPolicy.tenant}
                        onChange={handleNewPolicyChange('tenant')}>
                        {tenants.map((tenant, key) => (
                          <MenuItem key={key} value={tenant.id}>{tenant.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                )} />
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button disabled={saving} onClick={handleClose} color="default">
            Cancel
          </Button>
          <Button
            disabled={saving} onClick={handleSave} color="primary"
            variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <ErrorSnackbar error={error} onDismiss={handleDismissError} />
    </>
  );
}
EditHook.propTypes = {
  allHooks: PropTypes.array.isRequired,
  classes: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  selectedHook: PropTypes.object,
};

export default withStyles(defaultStyles)(EditHook);