import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Caret from '@material-ui/icons/ArrowDropDown';
import LockIcon from '@material-ui/icons/Lock';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import AuthContext from '../contexts/AuthContext';
import styles from '../App.css.js';

export function Header({ classes }) {
  const history = useHistory();
  const [menuAnchor, setMenuAnchor] = useState();
  const { userInfo } = useContext(AuthContext);

  const handleMenuOpen = (event) => {
    setMenuAnchor(event.currentTarget);
  };
  const handleMenuClose = () => {
    setMenuAnchor();
  };
  const handleLogout = () => {
    history.push('/logout');
  };

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        <LockIcon style={{ marginRight: 6, marginTop: -4 }} />
        <Typography variant="h6">
          Colliers Authorization Manager
        </Typography>
        <div style={{ flex: 1 }} />
        {userInfo && (
          <>
            <Tooltip
              placement="left"
              title={`Logged in as ${userInfo.name}`}>
              <div
                onClick={handleMenuOpen} style={{
                  alignItems: 'center',
                  cursor: 'pointer',
                  display: 'flex',
                  flexDirection: 'row'
                }}>
                <div className={classes.toolbar} />
                <Avatar
                  alt={userInfo.name}
                  src={userInfo.picture}
                  style={{ border: '1px solid white' }} />
                <Caret />
              </div>
            </Tooltip>
            <Menu
              anchorEl={menuAnchor}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              keepMounted
              open={Boolean(menuAnchor)}
              onClose={handleMenuClose}>
              <MenuItem onClick={handleLogout}>
                <ListItemIcon><LogoutIcon /></ListItemIcon>
                <ListItemText primary="Logout" />
              </MenuItem>
            </Menu>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
}
Header.propTypes = {
  classes: PropTypes.object
};

export default withStyles(styles)(Header);