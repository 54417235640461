import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter } from 'react-router-dom';
import { createMuiTheme, withStyles, MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { AuthProvider } from './contexts/AuthContext';
import { DataProvider } from './contexts/DataContext';
import Header from './layout/Header';
import Sidebar from './layout/Sidebar';
import AuthenticatedRoutes from './AuthenticatedRoutes';
import defaultTheme from './theme.js';
import styles from './App.css.js';

export function App({ classes }) {
  const theme = createMuiTheme(defaultTheme);
  return (
    <BrowserRouter>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <div className={classes.root}>
          <AuthProvider>
            <DataProvider>
              <Header />
              <Sidebar />
              <main className={classes.main}>
                <div className={classes.toolbar} />
                <AuthenticatedRoutes />
              </main>
            </DataProvider>
          </AuthProvider>
        </div>
      </MuiThemeProvider>
    </BrowserRouter>
  );
}
App.propTypes = {
  classes: PropTypes.object
};

export default withStyles(styles)(App);