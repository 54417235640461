import React, { useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Fab from '@material-ui/core/Fab';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import DataContext from '../contexts/DataContext';
import { destroy } from '../services/api';
import logger from '../services/logger';
import ApplicationSelector from '../shared/ApplicationSelector';
import ErrorSnackbar from '../shared/ErrorSnackbar';
import { RolesIcon } from '../shared/icons';
import Confirm from '../shared/Confirm';
import FullPageLoader from '../shared/FullPageLoader';
import defaultStyles from '../App.css.js';
import CreateRole from './CreateRole';
import EditRole from './EditRole';

export function Roles({ classes }) {
  const [loading, setLoading] = useState(true);
  const [creating, setCreating] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);
  const [error, setError] = useState();
  const { roles, refreshRoles, sessionApplication } = useContext(DataContext);

  const refreshList = useCallback(async () => {
    setLoading(true);
    await refreshRoles();
    setLoading(false);
  }, [setLoading, refreshRoles]);

  useEffect(() => {
    refreshList();
  }, [refreshList]);

  const onNewRole = (role) => {
    refreshList();
    setSelectedRole(role);
  };
  const handleSelectRole = (role) => () => {
    setSelectedRole(role);
  };
  const toggleCreationDialog = (which) => () => {
    setCreating(which);
  };
  const handleEditClose = () => {
    setSelectedRole(null);
  };

  const handleDeleteRole = (role) => () => {
    setDeleting(role);
  };
  const handleDeleteCancel = () => {
    setDeleting();
  };
  const handleDeleteConfirm = () => {
    destroy('/roles', deleting.id).then(() => {
      setDeleting();
      refreshList();
    }, (err) => {
      logger.error(err);
      setDeleting();
      setError(
        `The ${deleting.name} role could not be deleted. `
        + 'This is probably because it is still assigned to one or more users / machines.'
      );
    });
  };

  const handleDismissError = () => {
    setError();
  };
  
  if (loading) {
    return <FullPageLoader />;
  }

  const appRoles = roles
    .filter((role) => role.application === sessionApplication.id)
    .sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1);
  return (
    <>
      <Fab color="primary" className={classes.fab} onClick={toggleCreationDialog(true)}>
        <AddIcon />
      </Fab>
      {creating && (
        <CreateRole
          application={sessionApplication.id}
          handleClose={toggleCreationDialog(false)}
          roles={appRoles}
          onChange={onNewRole} />
      )}
      {selectedRole && (
        <EditRole
          handleClose={handleEditClose}
          role={selectedRole} />
      )}
      {deleting && (
        <Confirm
          confirmButtonTxt="Delete"
          text={`Are you sure you want to delete the "${deleting.name}" role? This cannot be undone.`}
          handleConfirm={handleDeleteConfirm}
          handleCancel={handleDeleteCancel} />
      )}
      <ErrorSnackbar error={error} onDismiss={handleDismissError} />

      <div className={classes.titleWithSelector}>
        <Typography variant="h5">Role Management</Typography>
        <div style={{ flex: 1 }} />
        <ApplicationSelector />
      </div>

      <Paper className={classes.mainPaper}>
        {appRoles.length === 0
          ? (
            <Typography className={classes.na}>No roles have been created for this application.</Typography>
          )
          : (
            <List>
              {appRoles.map((role, index) => (
                <React.Fragment key={index}>
                  <ListItem
                    className={classes.clickableListItem}
                    onClick={handleSelectRole(role)}>
                    <ListItemIcon><RolesIcon /></ListItemIcon>
                    <ListItemText
                      primary={(
                        <>
                          {role.name}
                          <span className={classes.listItemPrimaryExtra}>{role.id}</span>
                        </>
                      )}
                      secondary={role.description} />
                    <ListItemSecondaryAction>
                      <IconButton
                        className={classes.subtleDeleteButton}
                        color="secondary"
                        onClick={handleDeleteRole(role)}>
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                </React.Fragment>
              ))}
            </List>
          )
        }
      </Paper>
    </>
  );
}
Roles.propTypes = {
  classes: PropTypes.object
};

export default withStyles(defaultStyles)(Roles);