import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Done';
import RemoveIcon from '@material-ui/icons/Clear';
import DataContext from '../contexts/DataContext';
import { read, create, destroy } from '../services/api';
import ErrorSnackbar from '../shared/ErrorSnackbar';
import FullPageLoader from '../shared/FullPageLoader';

export function EditRole({ handleClose, role }) {
  const [selectedPermIds, setSelectedPermIds] = useState(null);
  const [originalPermIds, setOriginalPermIds] = useState(null);
  const [policies, setPolicies] = useState(null);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState();
  const { permissions, sessionApplication } = useContext(DataContext);

  useEffect(() => {
    read(`/roles/${role.id}/policies`).then((policies) => {
      const permissionIds = policies.map((p) => p.permission.id);
      setOriginalPermIds(permissionIds);
      setSelectedPermIds(permissionIds);
      setPolicies(policies);
    });
  }, [role]);

  const handleSave = () => {
    if (!error) {
      const createPolicies = selectedPermIds.filter((p) => !originalPermIds.includes(p));
      const deletePolicies = policies.filter((p) => !selectedPermIds.includes(p.permission.id));
      if (createPolicies.length > 0 || deletePolicies.length > 0) {
        setSaving(true);
        Promise.all([
          Promise.all(
            createPolicies.map((permissionId) => create(`/roles/${role.id}/policies`, { permission: permissionId }))
          ),
          Promise.all(
            deletePolicies.map((policy) => destroy(`/roles/${role.id}/policies`, policy.id))
          )
        ]).then(
          () => {
            handleClose();
          },
          (err) => {
            if (err.message) {
              setError(err.message);
            } else if (err.status === 403) {
              setError('You do not have permission to do that.');
            } else {
              setError('An error has occurred. Please try again.');
            }
            setSaving(false);
          }
        );
      } else {
        setError('At least one change is required.');
      }
    }
  };

  const handlePermToggle = (perm) => () => {
    if (selectedPermIds.includes(perm.id)) {
      setSelectedPermIds(selectedPermIds.filter((id) => id !== perm.id));
    } else {
      setSelectedPermIds([...selectedPermIds, perm.id]);
    }
    setError();
  };

  const handleDismissError = () => {
    setError();
  };

  const appPermissions = permissions[sessionApplication.id];
  const resources = Object.keys(appPermissions || {});
  return (
    <>
      <Dialog open={true} onClose={handleClose}>
        <DialogTitle>Edit &quot;{role.name}&quot; Policies</DialogTitle>
        {!(permissions && selectedPermIds) && (
          <DialogContent>
            <FullPageLoader />
          </DialogContent>
        )}
        {permissions && selectedPermIds && (
          <>
            <DialogContent>
              {resources.length === 0
                ? (
                  <Typography>No permissions have been created yet.</Typography>
                )
                : (
                  <List dense>
                    {resources.map((resource, index) => (
                      <React.Fragment key={index}>
                        <ListItem disableGutters>
                          <ListItemText
                            disableTypography
                            primary={(
                              <Typography
                                style={{ fontWeight: 'bold' }}
                                variant="body2">{resource}</Typography>
                            )}
                            secondary={(
                              <>
                                {appPermissions[resource].map((perm, x) => {
                                  const selected = selectedPermIds.includes(perm.id);
                                  const original = originalPermIds.includes(perm.id);
                                  return (
                                    <Chip
                                      key={x}
                                      avatar={(original || selected)
                                        ? (
                                          <Avatar>
                                            {original && selected && (<CheckIcon fontSize="small" />)}
                                            {!original && selected && (<AddIcon fontSize="small" />)}
                                            {original && !selected && (<RemoveIcon fontSize="small" />)}
                                          </Avatar>
                                        )
                                        : (<></>)
                                      }
                                      clickable
                                      color={selected ? 'primary' : 'default'}
                                      label={perm.action}
                                      onClick={handlePermToggle(perm)}
                                      size="small"
                                      style={{ marginRight: 4 }}
                                      variant={selected ? 'default' : 'outlined'} />
                                  );
                                })}
                              </>
                            )} />
                        </ListItem>
                      </React.Fragment>
                    ))}
                  </List>
                )
              }
            </DialogContent>
            <DialogActions>
              <Button disabled={saving} onClick={handleClose} color="default">
                Cancel
              </Button>
              <Button
                disabled={saving} onClick={handleSave} color="primary"
                variant="contained">
                Save
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
      <ErrorSnackbar error={error} onDismiss={handleDismissError} />
    </>
  );
}
EditRole.propTypes = {
  handleClose: PropTypes.func.isRequired,
  role: PropTypes.object.isRequired
};

export default EditRole;
