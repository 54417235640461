import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { Ability } from '@casl/ability';
import { unpackRules } from '@casl/ability/extra';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import FullPageLoader from '../shared/FullPageLoader';
import { read } from '../services/api';
import auth from '../services/auth';

// TODO: we may need to refresh read('profile') results on any data change
//  (i.e. in case a superadmin is changing things that impact their own perms)

// TODO: don't really need to refresh read('profile') results if just a
//  sessionTenant selection change

const AuthContext = React.createContext();
export default AuthContext;

export function AuthProvider({ children }) {
  const history = useHistory();
  const location = useLocation();
  const [userInfo, setUserInfo] = useState(auth.getUserInfo());
  const [sessionTenant, setSessionTenant] = useState(localStorage.getItem('session_tenant') || null);
  const [error, setError] = useState();
  const [ability, setAbility] = useState();

  // keep session tenant backed-up to localstorage
  useEffect(() => {
    if (sessionTenant) {
      localStorage.setItem('session_tenant', sessionTenant);
    } else {
      localStorage.removeItem('session_tenant');
    }
  }, [sessionTenant]);

  // handle authentication flow
  const authenticated = !!auth.isLoggedIn();
  useEffect(() => {
    setError();

    // if authing...
    if (location.hash) {
      // (authenticated can change to true before redirect complete)
      if (!authenticated) {
        auth.handleAuthentication().then(async (nextUrl) => {
          await setUserInfo(auth.getUserInfo());
          history.replace(nextUrl);
        }, (err) => {
          setError(typeof err === 'object' ? err : { errorDescription: err || '' });
        });
      }

    // if authed...
    } else if (authenticated) {
      // if sessionTenant is just set, pull profile and ability within that context
      if (sessionTenant) {
        setAbility(null);
        read('user/profile?pack=1')
          .then((profile) => {
            const ability = new Ability(unpackRules(profile.permissions || []));
            setAbility({
              can: (action, resource) => ability.can(action, resource, sessionTenant)
            });
          })
          .catch(() => {
            setError({
              errorDescription: 'Could not fetch user.'
            });
          });
      
      // set sessionTenant if one is not set yet
      } else {
        setSessionTenant(process.env.REACT_APP_DEFAULT_TENANT_ID);
      }

    // if not authed or authing...
    } else {
      auth.login();
    }
  }, [authenticated, history, location.hash, sessionTenant]);

  // if error, display message
  if (error) {
    return (
      <div style={{ padding: '96px 24px', textAlign: 'center', width: '100%' }}>
        <Typography color="error" display="block" variant="h6">
          Authentication Error!
          {' '}{error.errorDescription}
          {error.error && ` (code: ${error.error})`}
        </Typography>
        <br />
        <Button onClick={auth.login} variant="contained">Try again.</Button>
      </div>
    );

  // else if ready, show children
  } else if (authenticated && ability) {
    return (
      <AuthContext.Provider
        value={{
          ability,
          userInfo,
          setUserInfo,
          sessionTenant,
        }}>
        {children}
      </AuthContext.Provider>
    );
  }

  return <FullPageLoader />;
}
AuthProvider.propTypes = {
  children: PropTypes.node
};
