import React, { useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Fab from '@material-ui/core/Fab';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import DataContext from '../contexts/DataContext';
import { update } from '../services/api';
import logger from '../services/logger';
import { TenantsIcon } from '../shared/icons';
import Confirm from '../shared/Confirm';
import ErrorSnackbar from '../shared/ErrorSnackbar';
import FullPageLoader from '../shared/FullPageLoader';
import defaultStyles from '../App.css.js';
import EditTenant from './EditTenant';

export function Tenants({ classes }) {
  const [creating, setCreating] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [editing, setEditing] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const { tenants, refreshTenants } = useContext(DataContext);

  const refreshList = useCallback(async () => {
    setLoading(true);
    await refreshTenants();
    setLoading(false);
  }, [setLoading, refreshTenants]);

  useEffect(() => {
    refreshList();
  }, [refreshList]);

  const toggleCreatingDialog = (which) => () => {
    setCreating(which);
  };

  const handleEditing = (tenant) => () => {
    if (tenant.id === process.env.REACT_APP_DEFAULT_TENANT_ID) {
      setError(`The ${tenant.name} tenant can not be edited.`);
    } else {
      setEditing(tenant);
    }
  };
  const handleEditClose = () => {
    setEditing(null);
  };

  const handleDeleteTenant = (tenant) => () => {
    setDeleting(tenant);
  };
  const handleDeleteCancel = () => {
    setDeleting();
  };
  const handleDeleteConfirm = () => {
    update(`/tenants/${deleting.id}`, { archived: true }).then(() => {
      setDeleting();
      refreshList();
    }, (err) => {
      logger.error(err);
      setDeleting();
      setError(`The ${deleting.name} tenant could not be archived.`);
    });
  };

  const handleDismissError = () => {
    setError();
  };

  if (loading) {
    return <FullPageLoader />;
  }

  const sortedTenants = tenants.sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1);
  return (
    <>
      <Fab color="primary" className={classes.fab} onClick={toggleCreatingDialog(true)}>
        <AddIcon />
      </Fab>
      {creating && (
        <EditTenant
          handleClose={toggleCreatingDialog(false)}
          onChange={refreshList}
          tenants={tenants} />
      )}
      {editing && (
        <EditTenant
          handleClose={handleEditClose}
          onChange={refreshList}
          selectedTenant={editing}
          tenants={tenants} />
      )}
      {deleting && (
        <Confirm
          confirmButtonTxt="Archive"
          text={`
            Are you sure you want to archive the "${deleting.name}" tenant?
            All related permissioning will be disabled.
          `}
          handleConfirm={handleDeleteConfirm}
          handleCancel={handleDeleteCancel} />
      )}
      <ErrorSnackbar error={error} onDismiss={handleDismissError} />

      <div className={classes.titleOnly}>
        <Typography variant="h5">Tenant Management</Typography>
      </div>

      <Paper className={classes.mainPaper}>
        {tenants.length === 0
          ? (
            <Typography className={classes.na}>No tenants have been created yet.</Typography>
          )
          : (
            <List>
              {sortedTenants.map((tenant, index) => {
                return (
                  <React.Fragment key={index}>
                    <ListItem
                      className={classes.clickableListItem}
                      onClick={handleEditing(tenant)}>
                      <ListItemIcon><TenantsIcon /></ListItemIcon>
                      <ListItemText
                        primary={(
                          <>
                            {tenant.name}
                            <span className={classes.listItemPrimaryExtra}>{tenant.id}</span>
                          </>
                        )}
                        secondary={tenant.description} />
                      {tenant.id !== process.env.REACT_APP_DEFAULT_TENANT_ID && (
                        <ListItemSecondaryAction>
                          <IconButton
                            className={classes.subtleDeleteButton}
                            color="secondary"
                            onClick={handleDeleteTenant(tenant)}>
                            <DeleteIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      )}
                    </ListItem>
                    <Divider />
                  </React.Fragment>
                );
              })}
            </List>
          )
        }
      </Paper>
    </>
  );
}
Tenants.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(defaultStyles)(Tenants);